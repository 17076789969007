import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import helpIcon from "../../images/help-icon.svg"

const Button = styled.button`
  display: inline-block;
  position: relative;
  padding: 0 1.875rem 0 0;
  border: 0;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.1875rem;
  overflow: visible;
  color: white;
  background: none;

  &::after {
    content: "";
    position: absolute;
    top: -0.0625rem;
    right: 0;
    width: 1.1875rem;
    height: 1.1875rem;
    border: 0.125rem solid white;
    border-radius: 50%;
    background: url(${helpIcon}) no-repeat 0.5rem center;
    background-size: 0.25rem 0.875rem;
  }

  @media (min-width: 1024px) {
    padding-right: 2.5rem;
    font-size: 1.25rem;
    line-height: 1.5rem;

    &::after {
      top: -0.0625rem;
      width: 1.625rem;
      height: 1.625rem;
      background-position: 0.6875rem center;
      background-size: 0.3125rem 1.1875rem;
    }
  }

  @media (min-width: 1200px) {
    padding-right: 3.0625rem;
    font-size: 1.5rem;
    line-height: 1.875rem;

    &::after {
      top: -0.125rem;
      width: 2rem;
      height: 2rem;
      background-position: 0.8125rem center;
      background-size: 0.375rem 1.4375rem;
    }
  }
`

const HelpButton = (props) => {
  const intl = useIntl()

  return (
    <Button {...props}>
      {intl.formatMessage({ id: "help" })}
    </Button>
  )
}

export default HelpButton
