import React, { createRef, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import get from "lodash/get"

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
`

const Wrapper = styled.div`
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: 600;
  padding: 1.25rem;
  transition: all .4s ease;
  width: calc(100% - 2rem);
  max-width: 25rem;
  background: white;
  box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  transform: translateX(-50%);
  animation: help-modal-slide-down 0.3s forwards;

  &:focus {
    outline: 0;
  }

  h2 {
    margin: 0 0 1.25rem 0;
    font-size: 2rem;
    text-align: center;
  }

  .good-luck {
    margin: 1rem 0 0.5rem 0;
    font-size: 1.17rem;
    text-align: center;
    font-weight: bold;
  }

  @keyframes help-modal-slide-down {
    0% { top: 0; }
    100% { top: 10% }
  }
`

const CloseButton = styled.button`
  padding: 0.25rem 0.625rem 0.25rem;
  border: 0;
  float: right;
  color: #343434;
  text-transform: uppercase;
  background: none;
  font: 1rem;
  line-height: 1.25rem;
`

const HelpModal = ({ lang, show, onClose }) => {
  const intl = useIntl()
  const ref = createRef()

  const { allTextsYaml } = useStaticQuery(graphql`
    query {
      allTextsYaml(filter: { _id: { eq: "memoryInstructions" } }) {
        nodes {
          lang
          text {
            html
          }
        }
      }
    }
  `)

  const instructions = get(allTextsYaml.nodes.find((x) => x.lang === lang), "text.html")

  useEffect(() => {
    if (show && ref.current) {
      ref.current.focus()
    }
  }, [show])

  if (!show) {
    return null
  }

  return (
    <div>
      <Bg onClick={onClose} />
      <Wrapper tabIndex="-1" ref={ref}>
        <h2>{intl.formatMessage({ id: "instructions" })}</h2>
        <div dangerouslySetInnerHTML={{ __html: instructions}} />
        <p className="good-luck">{intl.formatMessage({ id: "goodLuck" })}!</p>
        <CloseButton onClick={onClose}>
          {intl.formatMessage({ id: "close" })}
        </CloseButton>
      </Wrapper>
    </div>
  )
}

export default HelpModal
