import React from "react"
import shuffle from "lodash/shuffle"
import { graphql } from "gatsby"

import Memory from "../components/memory-game"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

export default function MemoryTemplate({
  data: { memoryYaml: { title, images } },
  pageContext: { lang, pagePath, lsKey, embed },
}) {
  const getImage = (img, n) => ({
    id: `${img.name}-${n}`,
    name: img.name,
    pic: img.image,
    flipped: false,
  })
  const allImages = shuffle(images.map((img) => getImage(img, 1)).concat(images.map((img) => getImage(img, 2))))

  return (
    <>
      <Memory lang={lang} title={title} path={pagePath} images={allImages} lsKey={lsKey} embed={embed} />
      {!embed && <BrowserUpdateMessage />}
      {!embed && <CookieBanner lang={lang} />}
    </>
  )
}

export const pageQuery = graphql`
  query($lang: String, $_id: String) {
    memoryYaml(lang: { eq: $lang }, _id: { eq: $_id }) {
      title
      images {
        image
        name
      }
    }
  }
`
