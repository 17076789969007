import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import capitalize from "lodash/capitalize"

import timerIcon from "../images/timer-icon.svg"
import restartIcon from "../images/restart-icon.svg"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 65.75rem;
  min-height: 2.375rem;
  margin: 1.625rem auto;
  padding: 0 calc(1.5% + 1.25rem);
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 2.25rem;
  color: #666;

  @media (max-width: 500px) {
    margin-bottom: 4.75rem;
  }

  @media (min-width: 1024px) {
    margin: 2.875rem auto 3.125rem;
    font-size: 1.25rem;
    line-height: 3.125rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.5rem;
  }
`

const Time = styled.div`
  padding: 0rem 0.6875rem;
  border: none;
  border-radius: 100rem;
  background: rgba(255,255,255,0.4);
  color: #333;

  &::before {
    content: "";
    display: inline-block;
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
    vertical-align: middle;
    background: url(${timerIcon}) no-repeat center;
    background-size: contain;
    filter: contrast(200%);
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  @media (min-width: 1024px) {
    &::before {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.6875rem;
    }
  }
`

const Score = styled.div`
  display: inline-block;
  padding: 0rem 0.6875rem;
  border: none;
  border-radius: 100rem;
  background: rgba(255,255,255,0.4);
  color: #333;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 500px) {
    position: absolute;
    top: 3.5rem;
    width: auto;
    text-align: center;
    width: calc( 100% - 3rem );
  }
`

const ResetButton = styled.button`
  position: relative;
  padding: 0.5625rem 2.3125rem 0.5625rem 0.75rem;
  border: 0;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: rgb(200,0,0);
  text-decoration: none;
  background: rgba(255, 255, 255, 0.7);

  &::after {
    content: "";
    position: absolute;
    top: 0.25rem;
    right: 0.3125rem;
    width: 1.625rem;
    height: 1.625rem;
    background: url(${restartIcon}) no-repeat center;
    background-size: contain;
  }

  @media (min-width: 1024px) {
    padding: 0.9375rem 3.5rem 0.9375rem 0.9375rem;
    font-size: 1rem;
    line-height: 1.25rem;

    &::after {
      top: 0.5625rem;
      right: 0.6875rem;
      width: 2rem;
      height: 2rem;
    }
  }
`

const GameOptions = ({ time, score, turns, onReset, ...props }) => {
  const intl = useIntl()
  const hours = Math.floor(time / 3600).toString()
  const minutes = Math.floor((time - hours * 3600) / 60).toString()
  const seconds = (time - (hours * 3600) - (minutes * 60)).toString()
  const numberPadding = (n) => n.length < 2 ? `0${n}` : n

  return (
    <Wrapper {...props}>
      <Time>
        <span>{numberPadding(hours)}:{numberPadding(minutes)}:{numberPadding(seconds)}</span>
      </Time>
      <Score>
        {intl.formatMessage({ id: "turns" })}: {turns} – {capitalize(intl.formatMessage({ id: "score" }))}: {score}
      </Score>
      <ResetButton onClick={onReset}>
        {intl.formatMessage({ id: "reset" })}
      </ResetButton>
    </Wrapper>
  )
}

export default GameOptions
