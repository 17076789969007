import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import useReducedMotion from "../hooks/reducedMotion"
import { useStore } from "../hooks/store"
import getRoomLink from "../utils/get-room-link"

import GameButton from "./GameButton"
import sparkles from "../images/sparkles.gif"

const Wrapper = styled.div`
  position: relative;
  padding: 1.125rem;

  :focus {
    outline: 0;
  }

  h1 {
    font-size: 1.875rem;
    font-weight: normal;
    text-align: center;
  }

  p {
    margin: 0 0 3.75rem;
    text-align: center;
    font-size: 1.375rem;
    color: #111;

    b {
      display: block;
      font-family: ${props => props.theme.headingFont};
      line-height: 1.1;
      font-weight: normal;

      &.score {
        margin-top: 0.75rem;
        font-size: 7.5rem;
        line-height: 0.9;
      }
    }
  }

  @media (min-width: 932px) {
    h1 {
      font-size: 2.75rem;
    }

    .column {
      width: 50%;
    }

    .column-1 {
      p {
        margin-bottom: 0;
        font-size: 2rem;

        b.score {
          font-size: 10.94rem;
        }
      }
    }

    .column-2 {
      p {
        margin-bottom: 3rem;
        font-size: 2rem;
      }
    }
  }
`

const Inner = styled.div`
  ${props => !props.noAnimation && `
    background: url(${sparkles}) no-repeat center center;
    background-size: contain;
  `}

  @media (min-width: 932px) {
    display: flex;
    width: 100%;
  }
`

const PartyPopper = styled.div`
  font-size: 5rem;
  text-align: center;
`

const ButtonContainer = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;

  button,
  a {
    margin: 0 auto 1.25rem;
  }
`

export default function GameResultPage({ lang, score, maxScore, onTryAgain, highscoreKey, ...props }) {
  const intl = useIntl()
  const ref = useRef(null)
  const shouldReduceMotion = useReducedMotion()
  const [store, updateStore] = useStore()
  const highscore = store[highscoreKey]

  useEffect(() => {
    if (score > highscore) {
      updateStore((store) => {
        store[highscoreKey] = score
      })
    }
  })

  useEffect(() => {
    ref.current.focus()
  }, [])

  return (
    <Wrapper tabIndex="-1" ref={ref} {...props}>
      <Inner noAnimation={shouldReduceMotion || !store.animationsEnabled}>
        <div className="column column-1">
          <PartyPopper>&#127881;</PartyPopper>
          <h1>{intl.formatMessage({ id: "congrats" })}!</h1>
          <p><b className="score">{score}</b> {intl.formatMessage({ id: "outOf" })} {maxScore} {intl.formatMessage({ id: "points" })}</p>
        </div>
        <div className="column column-2">
          <p><b>{intl.formatMessage({ id: "yourHighScoreIs" })}</b> {highscore} {intl.formatMessage({ id: "points" })}</p>
          <ButtonContainer>
            <div>
              <GameButton onClick={onTryAgain}>{intl.formatMessage({ id: "tryAgain" })}</GameButton>
            </div>
            <div>
              <GameButton to={getRoomLink(6, lang)}>{intl.formatMessage({ id: "finish" })}</GameButton>
            </div>
          </ButtonContainer>
        </div>
      </Inner>
    </Wrapper>
  )
}
