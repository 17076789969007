import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import cardBg from "../../images/memory-card-bg.svg"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  lef: 0;
  width: 100%;
  height: 100%;

  .card-front,
  .card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.375rem solid;
    box-shadow: 0.0625rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.25);
    border-radius: 0.75rem;
    transition: transform 0.6s;
    backface-visibility: hidden;
  }

  .card-front {
    border-color: #F6A800;
    background: #F6A800 url(${cardBg}) no-repeat center;
    background-size: 125%;
  }

  .card-back {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    border-color: #86CEE0;
    background-color: #86CEE0;
    transform: rotateY(-180deg);

    img {
      width: 100%;
      height: auto;
    }
  }

  &.is-flipped .card-front {
    transform: rotateY(180deg);
  }

  &.is-flipped .card-back {
    transform: rotateY(0deg);
  }
`

const Button = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  background: none;
  perspective: 500px;
`

const CardBg = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const Card = ({ onClick, id, name, pic, isFlipped }) => {
  const intl = useIntl()

  return (
    <Wrapper className={isFlipped ? "is-flipped" : ""}>
      <Button onClick={() => isFlipped ? null : onClick({ id, name })} aria-pressed={!!isFlipped}>
        <span className="card-back" aria-hidden={!isFlipped}>
          <CardBg style={{ backgroundImage: `url(${encodeURI(pic)})` }}>
            <span className="structural">{name}</span>
          </CardBg>
        </span>
        <span className="card-front" aria-hidden={!!isFlipped}>
          <span className="structural">{intl.formatMessage({ id: "flipCard" })}</span>
        </span>
      </Button>
    </Wrapper>
  )
}

export default Card
