import React, { useState, useEffect } from "react"
import styled from "styled-components"
import shuffle from "lodash/shuffle"
import { useIntl } from "gatsby-plugin-intl"

import bgImage from "../../images/hilding-vilding-bg.svg"
import hvLogo from "../../images/hildingvilding_spion.svg"

import NwHelmet from "../NwHelmet"
import Header from "../HeaderSub"
import GameOptions from "../GameOptions"
import Game from "./Game"
import GameResultPage from "../GameResultPage"
import HelpButton from "./HelpButton"
import HelpModal from "./HelpModal"

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(0deg, rgba(0,100,170,0.8) 0%, rgb(132,201,240) 100%);

  > div {
    width: 100%;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    max-width: 65.75rem;
    margin: 0 auto;
    padding: 0 calc(1.5% + 1.25rem) 2rem;
  }
`

const StyledGameResultPage = styled(GameResultPage)`
  position: fixed;
  top: 3rem;
  left: 0;
  width: 100%;
  height: calc(100% - 3rem);
  overflow: auto;
  background: linear-gradient(180deg, rgba(0, 100, 170, 0.95) 0%, rgba(72, 160, 244, 0.95) 100%);

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: linear-gradient(180deg, rgba(0, 100, 170, 0.85) 0%, rgba(72, 160, 244, 0.85) 100%);
    backdrop-filter: blur(10px);
  }

  &,
  p {
    color: white;
  }

  @media (min-width: 932px) {
    .column-2 {
      padding-top: 2.375rem;

      p {
        margin-bottom: 6.7rem;
      }
    }
  }

  @media (min-width: 932px) and (min-height: 500px) {
    display: flex;
    align-items: center;
  }
`

let timerInterval

const Index = ({ lang, path, title, images, lsKey, embed }) => {
  const intl = useIntl()
  const defaultScore = 1000
  const date = new Date()
  const [startTime, setStartTime] = useState(date.getTime())
  const [showEndGame, setShowEndGame] = useState(false)
  const [score, setScore] = useState(defaultScore)
  const [turns, setTurns] = useState(0)
  const [time, setTime] = useState(0)
  const [gameResets, setGameResets] = useState(0)
  const [cards, setCards] = useState(images)
  const [showHelpModal, setShowHelpModal] = useState(false)

  useEffect(() => {
    timerInterval = setInterval(() => {
      if (!showEndGame) {
        // Decrease score by 2 for every 10 seconds
        if ((time + 1) % 10 === 0) {
          setScore(score - 2)
        }

        const currentTime = new Date()
        setTime(Math.floor((currentTime.getTime() - startTime) / 1000))
      }
    }, 1000)

    return () => { clearInterval(timerInterval) }
  })

  useEffect(() => {
    if (turns) {
      // Decrease score by 10 for every turn
      setScore(score - 10)
    }
  }, [turns])

  const handleEndGame = (newGame = false, timeout = 0) => {
    setShowEndGame(!newGame)

    setTimeout(() => {
      setCards(shuffle(images))
    }, timeout)

    if (newGame) {
      setTurns(0)
      setScore(defaultScore)

      const currentTime = new Date()
      setStartTime(currentTime.getTime())
      setTime(0)
    }
  }

  const handleReset = () => {
    setGameResets(gameResets + 1)
  }

  return (
    <Wrapper>
      <NwHelmet
        lang={lang}
        path={path}
        title={intl.formatMessage({ id: "memory" })}
        image={{
          url: `/assets/share-images/NW_Delning8_HV_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
          width: 1200,
          height: 630,
        }}
        noIndex={embed}
      />
      <div>
        {!embed && (
          <Header
            lang={lang}
            title={title}
            room={6}
            roomSymbol={hvLogo}
          />
        )}
        <GameOptions
          score={score}
          turns={turns}
          time={time}
          onReset={handleReset}
          showEndGame={showEndGame}
        />
        <Game
          endGame={handleEndGame}
          cards={cards}
          onTurn={() => setTurns(turns + 1)}
          gameResets={gameResets}
        />
        <footer>
          <HelpButton onClick={() => setShowHelpModal(true)} />
          <HelpModal lang={lang} show={showHelpModal} onClose={() => setShowHelpModal(false)}/>
        </footer>
        {showEndGame &&
          <StyledGameResultPage
            lang={lang}
            score={score}
            maxScore={1000}
            onTryAgain={handleEndGame}
            highscoreKey={lsKey}
          />
        }
      </div>
    </Wrapper>
  )
}

export default Index
