import React from "react"
import styled from "styled-components"
import { Link } from "gatsby";

const buttonStyle = `
  display: inline-block;
  padding: 0.75rem 1.5rem 0.7rem;
  border-radius: 9999px;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(255, 255, 255, 0.6);

  @media (min-width: 932px) {
    padding: 1rem 2rem 0.9rem;
    font-size: 1.125rem;
  }
`

const Button = styled.button`
  ${buttonStyle}
  border: 0;
  font: 1rem/1.1 ${props => props.theme.headingFont};
`

const StyledLink = styled(Link)`
  ${buttonStyle}
  text-decoration: none;
  font: 1rem/1.1 ${props => props.theme.headingFont};
`

export default function QuizButton({ onClick, to, children }) {
  return (
    to ?
      <StyledLink to={to}>{children}</StyledLink>
    :
      <Button onClick={onClick}>{children}</Button>
  )
}
